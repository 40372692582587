
$(function() {
/*
*** Dropdown material design
*/
    $("input.floating-input").each(function(){
      var $input =  $(this).val();
           if ($input) {
                  $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'top':'-25px','font-size':'15px!important','color':'#5264AE'})
           }else{
             $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'color':'#999','font-size':'17px',' font-weight':'normal','position':'absolute',
           'pointer-events':'none','left':'5px','top':'5px','transition':'0.2s ease all','-moz-transition':'0.2s ease all','-webkit-transition':'0.2s ease all' })
           }
        })

        $("input.floating-input").click(function(){
                  $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'top':'-25px','font-size':'15px!important','color':'#5264AE'})
        })

    $("input.floating-input").blur(function(){
      var $input =  $(this).val();
           if ($input) {
                  $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'top':'-25px','font-size':'15px!important','color':'#5264AE'})
           }else{
             $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'color':'#999','font-size':'17px',' font-weight':'normal','position':'absolute',
           'pointer-events':'none','left':'5px','top':'5px','transition':'0.2s ease all','-moz-transition':'0.2s ease all','-webkit-transition':'0.2s ease all' })
           }
        })

    $("input.floating-input").on('change',function(){
      var $input =  $(this).val();
           if ($input) {
                  $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'top':'-25px','font-size':'15px!important','color':'#5264AE'})
           }else{
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'color':'#999','font-size':'17px',' font-weight':'normal','position':'absolute',
           'pointer-events':'none','left':'5px','top':'5px','transition':'0.2s ease all','-moz-transition':'0.2s ease all','-webkit-transition':'0.2s ease all' })
           }
        })

    $("input.floating-input").on('keypress',function(){
      var $input =  $(this).val();
           if ($input) {
                  $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'top':'-25px','font-size':'15px!important','color':'#5264AE'})
           }else{
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({'color':'#999','font-size':'17px',' font-weight':'normal','position':'absolute',
           'pointer-events':'none','left':'5px','top':'5px','transition':'0.2s ease all','-moz-transition':'0.2s ease all','-webkit-transition':'0.2s ease all' })
           }
        })

///////////////////

    $("#monthPicker").datetimepicker({
      allowInputToggle: true,
      format: 'YYYY-MM'
    });

     $("#weeklyDatePicker").datetimepicker({
      allowInputToggle: true,
      format: 'YYYY-MM-DD'
    });
    $("#usermonthPicker").datetimepicker({
      allowInputToggle: true,
      format: 'YYYY-MM'
    }); 
    var value = moment().format("DD-MM-YYYY");
    var firstDate = moment(value, "DD-MM-YYYY").day(1).format("DD-MM-YYYY");
    var lastDate = moment(value, "DD-MM-YYYY").day(7).format("DD-MM-YYYY");
    $("#weeklyDatePicker").val(firstDate + " - " + lastDate);

    $('#weeklyDatePicker').on('focus', function (e) {
        var value = $("#weeklyDatePicker").val();
        var firstDate = moment(value, "DD-MM-YYYY").day(1).format("DD-MM-YYYY");
        var lastDate = moment(value, "DD-MM-YYYY").day(7).format("DD-MM-YYYY");
        $("#weeklyDatePicker").val(firstDate + " - " + lastDate);
    });

    $('#weeklyDatePicker').on('dp.change', function (e) {
        var value = $("#weeklyDatePicker").val();
        var firstDate = moment(value, "DD-MM-YYYY").day(1).format("DD-MM-YYYY");
        var lastDate = moment(value, "DD-MM-YYYY").day(7).format("DD-MM-YYYY");
        $("#weeklyDatePicker").val(firstDate + " - " + lastDate);
    });
    
        $('.form-material.floating > .form-control').each(function () {
        var $input = jQuery(this);
        var $parent = $input.parent('.form-material');

        if ($input.val()) {
            $parent.addClass('open');
        }

        $input.on('change', function () {
            if ($input.val()) {
                $parent.addClass('open');
            } else {
                $parent.removeClass('open');
            }
        });

                $('.form-material.floating > .form-control.materialDropDown').each(function () {
        var $input = jQuery(this);
        var $parent = $input.parent().parent('.form-material');

        if ($input.val()) {
            $parent.addClass('open');
        }

        $input.on('change', function () {
            if ($input.val()) {
                $parent.addClass('open');
            } else {
                $parent.removeClass('open');
            }
        });
    });

    });
});

$(function (){
    'use strict'

var $button = $(".toggleButton"),$body = $("body");

var windowwidth = $(window).width();

$(window).on("load",function(){
    if(windowwidth <=992){
        $body.addClass('toggle');
    }
})

$(window).on('resize',function(){
    if(windowwidth<=992){
        $body.addClass('toggle');
    }
})
})


